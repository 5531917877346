@import '_bootstrap/bootstrap';
@import 'monokai.css';

html {
  position: relative;
  min-height: 100%;
}

.header-logo {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: .16em;
}

.header-elements {
  font-weight: 600;
  font-size: 16px;
  text-transform: lowercase
}

.footer-elements {
  font-weight: 500;
  font-size: 15px;
  text-transform: lowercase
}

.page-default-title {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: .16em;
  text-transform: uppercase
}

.page-default-text {
  font-style: normal;
  font-size: 16px;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }
}

.project-page-list > a {
  border: 1px solid;
  border-color: $dark;
}

.project-page-list > a:hover {
  border-color: $gray-700;
  text-decoration: none;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-x: auto;
}

.img-tiny {
  max-width: 200px;
  width: 30%;
}

.img-small {
  max-width: 300px;
  width: 50%;
}

.img-medium {
  max-width: 400px;
  width: 60%;
}

.img-large {
  max-width: 500px;
  width: 80%;
}

.img-xlarge {
  max-width: 800px;
  width: 90%;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Intended for code blocks */
details > summary .details-header::before {
  content: "--- Click to expand code snippet ---";
}

details[open] > summary .details-header::before {
  content: "--- Click to close code snippet ---";
}

details.code-block > summary::marker {
  content: "";
}

details.code-block > summary {
  list-style: none;
}

details.code-block > summary::-webkit-details-marker {
  display: none;
}

details[open] > summary {
  height: auto;
}

details figure, pre {
  margin-bottom: 0px;
}

.hr-visible {
  color: $gray-700; 
  background-color: $gray-700;
}